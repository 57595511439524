// Proximanova Regular
@font-face {
  font-family: Proximanova;
  src: url('../../fonts/Proximanova_regular.otf');
}

@font-face {
  font-family: Proximanova;
  font-style: italic;
  src: url('../../fonts/Proximanova_regular_italic.otf');
}

// Proximanova Bold
@font-face {
  font-family: Proximanova;
  font-weight: 700;
  src: url('../../fonts/Proximanova_bold.otf');
}

@font-face {
  font-family: Proximanova;
  font-weight: 700;
  font-style: italic;
  src: url('../../fonts/Proximanova_bold_italic.otf');
}

// Proximanova Black
@font-face {
  font-family: Proximanova;
  font-weight: 900;
  src: url('../../fonts/Proximanova_black.otf');
}

@font-face {
  font-family: Proximanova;
  font-weight: 900;
  font-style: italic;
  src: url('../../fonts/Proximanova_black_italic.otf');
}

// Proximanova Medium
@font-face {
  font-family: Proximanova;
  font-weight: 500;
  src: url('../../fonts/Proximanova_medium.otf');
}

@font-face {
  font-family: Proximanova;
  font-weight: 500;
  font-style: italic;
  src: url('../../fonts/Proximanova_medium_italic.otf');
}

// Proximanova Light
@font-face {
  font-family: Proximanova;
  font-weight: 300;
  src: url('../../fonts/Proximanova_light.otf');
}

@font-face {
  font-family: Proximanova;
  font-weight: 300;
  font-style: italic;
  src: url('../../fonts/Proximanova_light_italic.otf');
}

// Proximanova Thin
@font-face {
  font-family: Proximanova;
  font-weight: 100;
  src: url('../../fonts/Proximanova_thin.otf');
}

@font-face {
  font-family: Proximanova;
  font-weight: 100;
  font-style: italic;
  src: url('../../fonts/Proximanova_thin_italic.otf');
}

// FreightText Regular
@font-face {
  font-family: FreightText;
  src: url('../../fonts/Freighttextpro_book.otf');
}

@font-face {
  font-family: FreightText;
  font-style: italic;
  src: url('../../fonts/Freighttextpro_book_italic.otf');
}

// FreightText Bold
@font-face {
  font-family: FreightText;
  font-weight: 700;
  src: url('../../fonts/Freighttextpro_bold.otf');
}

@font-face {
  font-family: FreightText;
  font-weight: 700;
  font-style: italic;
  src: url('../../fonts/Freighttextpro_bold_italic.otf');
}

// FreightText Black
@font-face {
  font-family: FreightText;
  font-weight: 900;
  src: url('../../fonts/Freighttextpro_black.otf');
}

@font-face {
  font-family: FreightText;
  font-weight: 900;
  font-style: italic;
  src: url('../../fonts/Freighttextpro_black_italic.otf');
}

// FreightText Medium
@font-face {
  font-family: FreightText;
  font-weight: 500;
  src: url('../../fonts/Freighttextpro_medium.otf');
}

@font-face {
  font-family: FreightText;
  font-weight: 500;
  font-style: italic;
  src: url('../../fonts/Freighttextpro_medium_italic.otf');
}

// FreightText Light
@font-face {
  font-family: FreightText;
  font-weight: 300;
  src: url('../../fonts/Freighttextpro_light.otf');
}

@font-face {
  font-family: FreightText;
  font-weight: 300;
  font-style: italic;
  src: url('../../fonts/Freighttextpro_light_italic.otf');
}
