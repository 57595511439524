@import "base";


.hwi-btn {
  &.hwi-btn-primary {
    background-color: $theme-color-button-primary;
    color: #fff;

    &:hover {
      opacity: 0.5;
    }
  }
}
