@use '@angular/material' as mat;
@include mat.core();
@import 'assets/styles/components/base';
@import 'assets/styles/components/bootstrap';
@import 'assets/styles/components/vendor';
@import 'assets/styles/components/buttons';
@import 'assets/styles/components/typography';
@import 'assets/styles/modules/colors';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Apr 05, 2023    AFLL-16880 - Supporting Angular CLI build + Angular 15 & refactoring
 *  HSenevir    02          Apr 10, 2023    AFLL-16880 Fixes for Angular 15 Material updates
 *  HSenevir    03          May 12, 2023    AFLL-17929 Fixing style issue for Angular 15 Material
 *  HSenevir    04          May 18, 2023    AFLL-17980 Fixing style issue media response buttons
 *  RPenimos    05          May 29, 2023    AFLL-17611 cookie policy pop-up is not properly displayed
 *  MKalaval    06          Jul 11, 2023    AFLL-17977 - Cemented/Uncemented - Approach  & Computer Assisted Surgery
 *  EElangav    07          Aug 18, 2023    AFLL-17559 Hip Risk Score | 'Risk of Undesired Outcomes' on the 'Risk Score' tab in 'Hip' surgery patients' profiles
 *  PPareek     08          Apr 04, 2024    AFLL-20577 - Fixed CSS issue due to angular version change.
 *  PPareek     09          May 09, 2024    AFLL-20577 - Fixed CSS issue due to angular version change.
 *  PPareek     10          Jul 12, 2024    AFLL-71762 - Changes done to Upgrade Angular and Angular Material to 18 
 */

/*
Applying custom angular material theming
*/
$vely-primary: mat.$cyan-palette;
$velys-accent: mat.$azure-palette; /// check which one to use

$my-theme: mat.define-theme((
        color: (
          theme-type: light,
          primary: $vely-primary,
          tertiary: $velys-accent
        )
));
//Applying custom theme to checkbox
html {
  @include mat.checkbox-theme($my-theme);
  @include mat.tabs-theme($my-theme);
  @include mat.radio-theme($my-theme);
}

body {
  min-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Rubik, sans-serif;
}

html {
  height: 100%;
  width: 100%;
}

.toast-container {
  font-family: Rubik, sans-serif;

  .toast-title {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .ngx-toastr {
    line-height: 20px;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
  }
}

// Angular 15 material style overriding
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  background-color: $velys-sky-blue !important;
  border-color: $velys-sky-blue !important;
}

.mat-mdc-checkbox .mdc-checkbox:hover,
.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  border-color: $rebranding-lightest-gray !important;
  background-color: transparent;
}

input[type=file]:focus, input[type=radio]:focus, input[type=checkbox]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above,
.mat-mdc-button .mat-mdc-button-persistent-ripple,
.mdc-tab__ripple {
  display: none !important;
}

.mdc-line-ripple::before, .mdc-line-ripple::after {
  border: none !important;
}

.mdc-floating-label {
  font-weight: normal;
}

.mat-mdc-select-panel {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12) !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__outer-circle {
  border-color: $rebranding-velys-sky-blue !important;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle {
  border-color: $rebranding-velys-sky-blue !important;
  transform: scale(0.5) !important;
}

.vras-radio .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle {
  transform: scale(1) !important;
}

.cemented-uncemented-mat-radio .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__inner-circle {
  transform: scale(1) !important;
}

.mat-mdc-dialog-surface {
  padding: 24px;
}

.mat-mdc-card-header.info-card-header {
  padding: 0;
}

.mat-mdc-card.mdc-card.info-card-container {
  background: $white;
}

.input-group {
  hp-hcp-phone-number-region-based input {
    padding: 7px 10px 10px 15px;
  }

  .mat-mdc-form-field-flex {
    height: 40px;
  }
}

.emr-info-block-right {
  hp-hcp-phone-number-region-based input {
    padding: 7px 10px 10px 15px;
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-mdc-select-value, .mdc-floating-label {
  padding: 5px 5px 5px 9px;
  background-color: transparent;
}

.navigation-text.mdc-button {
  padding: 0 16px !important;
}

.mdc-button {
  display: inline-block !important;
  letter-spacing: normal !important;
}

.mdc-form-field>label {
  font-weight: 500;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0 !important;
}

.form-input .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding: 0 !important;
}

.form-input {
  .mat-mdc-select {
    font-size: 15px;
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    background-color: unset; 
    pointer-events: unset;
  }

  .mat-mdc-select-disabled .mat-mdc-select-value {
    color: $rebranding-secondary-black;
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: black;
  font-weight: 500;
}

.mdc-checkbox__ripple, .mdc-checkbox__ripple:focus, .mdc-checkbox__ripple:hover,
.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before,
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  opacity: 0 !important;
  background-color: transparent !important;
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  border-color: initial !important;
}

.mat-mdc-option.mat-mdc-option-active {
  background: $white;
}

.no-padding {
  padding: 0;
}

.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
}

.mdc-menu-surface.mat-mdc-select-panel {
  border-radius: unset !important;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
  max-height: 144px !important;
  width: 100% !important;
  outline: 0 !important;
}

.custom-tooltip .mdc-tooltip__surface {
  font-size: 14px;
  background-color: white !important;
  color: black !important;
  box-shadow: 3px 3px 5px 3px rgba(0, 0, 0, 0.26);
  border-color: transparent transparent $velys-secondary-white transparent;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-mdc-select-placeholder, .mat-mdc-select-value {
  color: $velys-secondary-black !important;
}

// Applying style to below onetrust class to fix bug: cookie policy pop up not displaying properly
.otPcCenter {
  height: 80%;
}

.risk-profile-header .mat-mdc-tab-labels {
  display: flex;
  justify-content: space-around;
}

.mat-mdc-form-field-type-mat-select {
  padding: 2px 2px 2px 2px !important;
  background-color: white !important;  
  font-family: Rubik, sans-serif;
}

.mat-mdc-select-trigger {
  background-color: transparent !important;
}

.mat-mdc-select-arrow-wrapper {
  padding: 5px 5px 5px 5px !important;
  display: flex;
  background-color: transparent !important;
}

.mat-mdc-select-arrow svg {
  display: none;
}

.mdc-menu-surface {
  box-sizing: border-box;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  max-width: calc(100vw - 20px);
}

.mdc-radio .mdc-label {
  font-size: 14px;
}

.mat-mdc-radio-button .mat-internal-form-field {
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-line-height: 20px !important;
}

.mat-mdc-checkbox .mat-internal-form-field {
  --mat-checkbox-label-text-size: 14px;
}