/**
 * Copyright: Copyright © 2023
 * This file contains trade secrets of Johnson & Johnson. No part may be reproduced or transmitted in any
 * form by any means or for any purpose without the express written permission of Johnson & Johnson.
 */
/*
 *  @author     @version    @date           @description
 *  EElangav    01          Apr 10, 2023    AFLL-15217 Report Including Total VRAS Patients Count
 *  HSenevir    02          May 05, 2023    AFLL-17673 Updated color code to not use opacity and use rgb value only
 *  PPareek     03          Dec 10, 2023    AFLL-19609 Fixed Sonar Issues | Removed extra semicolon.
 */
// TODO: this needs to be completely refactored/consolidated

//== Colors

//** Neutrals
$black: #000;
$white: #fff;
$transparent: transparent;
$solitude: #eff1f5;
$echo-blue: #acb5c7;
$charcoal: #4a4a4a;
$eclipse: #3e3e3e;
$concrete: #f2f2f2;
$light-grayish-blue: #fafafc;
$red: #f00;

//** Rebranding Colors

$rebranding-secondary-white: #F7F7F7;
$rebranding-tertiary-white: #F7F8FC;
$rebranding-lightest-gray: #D2D3D4;
$rebranding-lighter-gray: #B3B3B3;
$rebranding-light-gray: #707070;
$rebranding-slightly-dark-gray: #9B9A9B;
$rebranding-velys-disable-color: #9B9B9B;
$rebranding-dark-gray: #4A4A4A;
$rebranding-velys-light-gray: #63666a;
$rebranding-darkest-gray:  rgba(0, 0, 0, 0.16);
$rebranding-secondary-black: #212121;
$rebranding-velys-spinner-bg: rgba(33, 33, 33, 0.75);
$rebranding-velys-login-bg: rgba(32, 32, 35, 1.0);

$rebranding-velys-sky-lighter-blue: rgb(223 244 250);
$rebranding-velys-sky-light-blue: #52C8E7;
$rebranding-velys-sky-blue: #00B4E1;
$rebranding-but-blue: #1478B4;
$rebranding-slate-blue: #675DC5;
$rebranding-darkish-blue: #2065c6;
$rebranding-vras-selection-blue: #187AB5;

$rebranding-velys-connected: #589B0B;
$rebranding-velys-light-green: #00B463;
$rebranding-velys-dull-green: #4DCC93;

$rebranding-red: #f44336;
$rebranding-orange: #FA9772;
$rebranding-yellow: #E2A602;
$rebranding-light-yellow: #FBF2DB;

$rebranding-light-purple: rgba(103, 93, 197, 0.25);
$rebranding-dull-purple: #9B94D6;

$rebranding-velys-seperator :  #CECFCF;
$rebranding-pastal-yellow: rgba(255,180, 29, 0.15);
$rebranding-velys-purple: #D7D4F0;

//** Theme Colors

$theme-background: #F7F8FB ;

//** Velys Colors

$velys-secondary-black: #212121;
$velys-darkest-gray: rgba(0, 0, 0, 0.16);
$velys-slightly-dark-gray: #9B9A9B;
$velys-dark-gray: #4A4A4A;
$velys-light-gray: #707070;
$velys-secondary-light-gray: #DFDFDF;
$velys-secondary-white: #F7F7F7;
$velys-tertiary-white: #F8F9FC;
$velys-dark-blue: #2065c6;
$velys-sky-blue: #00B4E1;
$velys-warning-red: #D0011B;
$velys-slightly-light-gray : #B3B3B3;
$velys-lightest-gray: #e5e5e5;

//** Theme Color

$theme-blue:  #0303ba;
$theme-dark-blue: #0303ba;
$theme-darkest-blue: #000099;
$theme-light-blue: #0e51af;

$theme-dark-purple: #6e71d8;
$theme-purple: #7377f4;
$theme-light-purple: #a7a9e7;

$theme-color-lightish-gray: #d6d6d6;
$theme-color-light-gray: #e9e9e9;
$theme-color-lighter-gray: #f9f9f9;
$theme-color-lightest-gray: #f4f4f4;
$theme-color-neutral-gray: #d1d1d1;
$theme-color-soft-gray: #959393;
$theme-color-softer-gray: #cccccc;
$theme-color-strong-gray: #e5e5e5;
$theme-color-stronger-gray: #8b8b8b;
$theme-color-gray: #828282;
$theme-color-ash-gray: #C9C9C9;
$theme-color-ash-light-gray: #EEEEEE;
$theme-color-gray-dark: #dcdcdc;
$theme-color-gray-darkest: #646464;
$theme-color-green: #02a536;
$theme-color-border-gray: #e3e3e3;

$theme-color-lighter-blue: #a5dbf4;
$theme-color-light-blue: #199cd8;
$theme-color-blue: #619828;

$theme-color-violet: #906ab0;
$theme-color-violet-dark: #603484;

$theme-color-neutral-red: #f30617;
$theme-color-light-red: #fd636e;

//** Button Colors

$theme-color-button-default: $theme-color-light-gray;
$theme-color-button-primary: $theme-color-blue;

//** Input Colors

$theme-color-radio-primary: #3caadd;

$theme-color-schedule-date: $theme-color-violet-dark;
$theme-color-due-date: $theme-color-neutral-red;
$theme-color-surgery-date: $theme-color-blue;

//** Date Picker
$datepicker-light-gray: rgba(0, 0, 0, 0.125);

$rebranding-vras-selection-purple: #D7D4F0;
